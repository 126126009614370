import { useEffect } from "react";

const REDIRECT_URL = process.env["REACT_APP_REDIRECT_URL"] ?? "https://emulator.wtf";

/**
 * Wraps [JobsComponent] in such a way that URL param key is traded for a JWT token
 */
export default function RedirectComponent() {
  const params = new URLSearchParams(window.location.search);
  const key = params.get('key');
  const runUuid = params.get('run');

  useEffect(() => {
    window.location.href = REDIRECT_URL + '/r/' + runUuid + '?key=' + key;
  });

  return null;
}
