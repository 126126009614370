import './App.css';
import RedirectComponent from './RedirectComponent';

export default function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src='/logo.svg' alt='emulator.wtf logo' />
      </header>

      <RedirectComponent />
    </div>
  );
}
